import Grid from '@componentPrimitives/Grid';
import { BlockContainer } from '@components/Blocks';
import CtaButton from '@components/CtaButton';
import Image from '@components/Image';
import { createBlock } from '@lib/features/blocks/blocks';
import Txt from '@primitive/Txt';
import * as style from './CtaBlock.css';

const Cta2Block = createBlock<'blocks_cta2_BlockType'>(({ heading, content, linkCta, image }) => {
  return (
    <BlockContainer disableMargin colorSet="primary" paper className={style.cta2Root}>
      <Grid
        cx={{
          alignItems: 'center',
        }}>
        <Grid.Col {...{ [style.cta2Breakpoint]: 7 }} className={style.cta2ImageCol}>
          <Image
            transform="landscapeLgCrop"
            ShimProps={{ className: style.cta2ImageWrap }}
            ratio="landscapeWide"
            alt=""
            {...image}
          />
        </Grid.Col>
        <Grid.Col {...{ [style.cta2Breakpoint]: 5 }} className={style.cta2ContentCol}>
          <Txt variant="h4" as="h2" autoMargin>
            {heading}
          </Txt>
          <Txt html>{content}</Txt>
          {linkCta && <CtaButton variant="outlined" size="small" {...linkCta} cx={{ mT: 'md' }} />}
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default Cta2Block;
